/* SavedOptionsComponentUI.css */
.saved-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saved-option-item {
    margin: 10px 0;
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    cursor: pointer;
}

.saved-option-item:hover {
    background-color: #e2e6ea;
}