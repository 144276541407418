.burger-menu {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

.burger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.burger-icon span {
    width: 2rem;
    height: 0.25rem;
    background: #333;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

.menu-items {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.menu-items.open {
    transform: translateX(0);
}

.menu-items a {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;
}

.menu-items a:hover {
    color: #343078;
}

.sign-out-button {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 15px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sign-out-button:hover {
    background-color: #d32f2f;
}