/* RecommendationComponentUI.css */
.recommendation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recommendation-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.recommendation-button:hover {
    background-color: #218838;
}