/* CameraComponentUI.css */
.camera-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.camera-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.camera-button:hover {
    background-color: #0056b3;
}

.camera-component {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.camera-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.camera-controls button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.camera-controls button:hover {
    background-color: #0056b3;
}