.search-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.search-bar {
    display: flex;
    margin-bottom: 20px;
}

.search-bar input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
}

.search-bar button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.search-results ul {
    list-style-type: none;
    padding: 0;
}

.search-results li {
    background-color: #f1f1f1;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}