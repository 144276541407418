.daily-summary {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.summary-item {
    margin-bottom: 10px;
}

.progress-bar {
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar>div {
    height: 100%;
    background-color: #4caf50;
}