.today-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.dashboard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.balance-card {
    background: linear-gradient(to right, #E064F7, #FF8DAC);
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 48%;
}

.balance-card h2 {
    margin: 0;
    font-size: 1.2em;
}

.balance-card h1 {
    margin: 10px 0;
    font-size: 2.5em;
}

.balance-details {
    display: flex;
    justify-content: space-between;
}

.income,
.expenses {
    display: flex;
    flex-direction: column;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.action-buttons button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.action-buttons button:hover {
    background-color: #45a049;
}

.add-meal-button {
    font-size: 24px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF8DAC;
}

.meals-list {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
}

.meal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.meal-icon {
    font-size: 1.5em;
    margin-right: 10px;
}

.meal-details {
    flex-grow: 1;
}

.meal-name {
    font-weight: bold;
    display: block;
}

.meal-nutrients {
    font-size: 0.9em;
    color: #666;
}

.meal-amount {
    font-weight: bold;
    color: #FF8DAC;
}

.remove-meal {
    background-color: transparent;
    color: #f44336;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}

.remove-meal:hover {
    color: #d32f2f;
}
