.profile-view {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.profile-view form {
    display: flex;
    flex-direction: column;
}

.profile-view form>div {
    margin-bottom: 15px;
}

.profile-view label {
    display: block;
    margin-bottom: 5px;
}

.profile-view input,
.profile-view select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.profile-view button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-view button:hover {
    background-color: #0056b3;
}